import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class FaviconService {
	constructor(private router: Router) {
		// Listen for route changes
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd) // Filter for NavigationEnd events
			)
			.subscribe(() => {
				this.updateFavicon();
			});
	}

	updateFavicon() {
		const faviconLink = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
		const currentUrl = window.location.href;

		// Check the URL and change favicon accordingly
		if (currentUrl.includes('request-portal-form')) {
			faviconLink.href = '../../../favicon2.ico'; // Set to new favicon
		} else {
			faviconLink.href = '../../../favicon.ico'; // Default favicon
		}
	}
}
