import { Component, OnInit } from '@angular/core';
import { Environment } from '@env/environment';
import { FaviconService } from './shared/services/favicon-service';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	constructor(
		private readonly environment: Environment,
		private faviconService: FaviconService,
		private titleService: Title,
		private router: Router
	) {}
	ngOnInit() {
		window['thunderSf'] = this.environment.thunderSf;
		const thunderSfScript = document.getElementById('thunderSfScript');
		if (thunderSfScript) {
			thunderSfScript['src'] = this.environment.thunderSf.source;
		}
		this.faviconService.updateFavicon();
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.updateTitle();
		});
	}

	updateTitle(): void {
		const currentUrl = this.router.url;

		if (currentUrl.includes('request-portal-form')) {
			this.titleService.setTitle('Data Subject Access Request');
		} else {
			this.titleService.setTitle('KPA Platform');
		}
	}
}
