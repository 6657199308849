<div
	fxLayout="row"
	class=""
	class="item-field status-badge inline">
	<div
		*ngIf="label != null && label != ''"
		fxLayout="column"
		fxFlex="0 0 40%"
		class="align-items">
		<span> {{ label }} </span>
	</div>
	<div
		*ngIf="fullLength != true"
		fxLayout="column"
		fxFlex="0 0 40%"
		class="align-items">
		<div
			*ngIf="warning"
			class="warning">
			Fair
		</div>
		<div
			*ngIf="passing"
			class="passing">
			Good
		</div>
		<div
			*ngIf="failing"
			class="failing">
			Poor
		</div>
		<div
			*ngIf="dueDate"
			class="dueDate">
			{{ subLabel }}
		</div>
	</div>

	<div
		*ngIf="fullLength == true"
		fxLayout="column"
		fxFlex="0 0 100%"
		class="align-items">
		<div
			*ngIf="warning"
			class="warning">
			Fair
		</div>
		<div
			*ngIf="passing"
			class="passing">
			Good
		</div>
		<div
			*ngIf="failing"
			class="failing">
			Poor
		</div>
		<div
			*ngIf="dueDate"
			class="dueDate largeFont box-badge-look"
			[ngClass]="{ pastDue: pastDue }">
			{{ subLabel }}
		</div>

		<div
			*ngIf="passingBoxyLook"
			class="passing largeFont box-badge-look">
			{{ subLabel }}
		</div>

		<div
			*ngIf="isRequestLimitExceeded"
			class="warning-v2 largeFont box-badge-look">
			Request Limit Exceeded
		</div>
	</div>
</div>
